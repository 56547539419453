.awsui-visual-refresh {
  &.awsui-polaris-dark-mode {
    .book-event {
      .bookevent-steps-container {
        // background-color: #232f3e !important;
      }
    }
  }
  .book-event {
    .bookevent-steps-container {
      padding: 20px 20px 10px 20px;
      border-radius: 30px;
      h1 {
        margin-bottom: 50px !important;
        font-size: 42px !important;
        font-weight: 700 !important;
      }
      div[class*='awsui_colspan-12'] {
        .step {
          h2,
          div[data-classname='step__item__subtitle'] {
            text-align: center;
          }
        }
      }
      @media (max-width: 960px) {
        .step {
          h2,
          div[data-classname='step__item__subtitle'] {
            text-align: center !important;
          }
        }
        h1 {
          font-size: 32px !important;
        }
      }
      .step {
        display: flex;
        flex-direction: column;

        h2 {
          font-size: 20px !important;
          font-weight: 700 !important;
        }
        button {
          padding: 0 !important;
          &:hover {
            background-color: transparent !important;
            border-color: transparent !important;
            color: #89bdee !important;
          }
          span {
            font-weight: 400 !important;
          }
        }
        &__item {
          align-self: center;
          margin-bottom: 0px;
          position: relative;
          padding-left: 18px;
          padding-top: 18px;
          &__image {
            height: 200px;
            width: 200px;
            display: flex;
            justify-content: center;
            align-items: center;
            &__background {
              height: 200px;
              position: absolute;
              top: -10px;
              left: -10px;
              z-index: 0;
            }
            &__avatar {
              height: 160px;
              position: absolute;
              top: 0; /* Adjust positioning as needed */
              left: 0; /* Adjust positioning as needed */
              z-index: 1;
            }
          }
          // &__counter {
          //   background-color: #ff9900;
          //   height: 36px;
          //   width: 36px;
          //   border-radius: 18px;
          //   display: flex;
          //   justify-content: center;
          //   align-items: center;
          //   position: absolute;
          //   top: 0;
          //   left: 0;
          //   color: #000;
          // }
        }
      }
      &__browse_all {
        text-align: center;
        margin-top: 10px;
        margin-bottom: 40px;
        button {
          background-color: transparent !important;
        }
      }

      .step__item__image__background1 {
        height: 220px;
        position: absolute;
        top: -10px;
        left: -10px;
        z-index: 0;
        display: none;
      }
      .step__item__image__background2 {
        height: 220px;
        position: absolute;
        top: -10px;
        left: -10px;
        z-index: 0;
      }
      .step__item__image__background3 {
        height: 220px;
        position: absolute;
        top: -10px;
        left: -10px;
        z-index: 0;
      }
      .step__item__image__avatar1 {
        height: 210px;
        position: absolute;
        top: -9px;
        left: -17px;
      }
      .step__item__image__avatar2 {
        height: 200px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }
      .step__item__image__avatar3 {
        height: 200px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }
    }
  }
}
