.overview-container {
  display: flex;
  .overview-item {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-right: 222px;
    padding-left: 30px;
    border-right: 1px solid #d8d5d5;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      border-right: none;
    }
    h1 {
      font-size: 4rem;
      margin: 0 2.5rem 1rem 0;
      font-weight: 800;
      color: #0872d3;
    }
    h4 {
      margin-top: 0;
      margin-left: 0.1rem;
      font-size: 14px;
      margin-bottom: 1rem;
    }
    h3 {
      color: #0872d3;
      margin-top: 0.5rem;
    }
    #revenue {
      margin: 0;
    }
    #revenue_label {
      margin-left: -6rem;
    }
  }
}
