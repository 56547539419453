@use '~@amzn/awsui-design-tokens/polaris' as awsui;

@keyframes animate {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}
.awsui-visual-refresh {
  &.awsui-polaris-dark-mode {
    .event-card__container {
      .event-card-content {
        div[data-classname='topic'] {
          color: #b99ce7 !important;
        }
        div[data-classname='more-info'] {
          color: #f8f8f8 !important;
        }
      }
    }
  }
  .event-card__container {
    text-decoration: none;
    display: block;

    position: relative;
    border: none;
    outline: none;
    cursor: pointer;

    &:before {
      position: absolute;
      content: '';
      top: -2px;
      left: -2px;
      height: calc(100% + 4px);
      width: calc(100% + 4px);
      border-radius: 16px;
      z-index: 0;
      opacity: 0;
      filter: blur(5px);
      background: linear-gradient(
        45deg,
        #df2a5d,
        #ff9900,
        #fbd8bf,
        #38ef7d,
        #7ce8f4,
        #2074d5,
        #7c5aed,
        #f46ebb,
        #df2a5d
      );
      background-size: 200%;
      transition: opacity 0.3s ease-in-out;
      animation: animate 20s linear infinite;
    }
    &:hover {
      &:before {
        opacity: 1;
      }
      &:active {
        background: none;
        &:before {
          filter: blur(2px);
        }
      }
    }

    div[class*='awsui_variant-default'] {
      &:hover {
        cursor: pointer !important;
      }
      &::after {
        box-shadow: none !important;
      }
    }
    div[class^='awsui_content-wrapper'] {
      > div[class^='awsui_content'] {
        border: 1px solid #a9a6a657;
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
        border-top: 0;
      }
    }
    .event-card-content {
      padding: 14px 20px 18px;
      h4 {
        -webkit-line-clamp: 2;
        display: -webkit-box;
        overflow: hidden;
        -webkit-box-orient: vertical;
        height: 44px;
        margin: 0 !important;
      }
      .event-card__dot-separater {
        display: inline-block;
        height: 4px;
        width: 4px;
        border-radius: 10px;
        background-color: awsui.$color-text-empty;
        margin: 0 6px 1px;
      }
      .event-card__description {
        -webkit-line-clamp: 2;
        display: -webkit-box;
        overflow: hidden;
        -webkit-box-orient: vertical;
        font-weight: 700 !important;
      }
      .event-card__caption {
        color: #972709;
        font-weight: 700;
        .event-card__dot-separater {
          display: inline-block;
          height: 4px;
          width: 4px;
          border-radius: 10px;
          background-color: #972709;
          margin: 0 6px 1px;
        }
      }
      div[data-classname='topic'] {
        color: #51267f !important;
      }
      div[data-classname='more-info'] {
        color: #414d5c !important;
        display: flex;
        flex-direction: row;
        align-items: center;
        span {
          height: 18px !important;
          width: 12px !important;
          margin-right: 4px;
          svg {
            height: 12px !important;
            width: 12px !important;
          }
        }
      }
    }

    .container-content {
      height: 100%;
      position: relative;
      .learning-type-container {
        position: absolute;
        top: 10px;
        left: 10px;
        display: flex;
        border-radius: 4px;
        padding: 0px 6px;
        align-items: center;
      }
      .reviews-container {
        position: absolute;
        bottom: 10px;
        right: 10px;
        background: rgb(0, 0, 0);
        display: flex;
        border-radius: 4px;
        padding: 0px 6px;
        align-items: center;

        .review-stars {
          display: flex;
          svg {
            width: 16px;
            height: 18px;
          }
        }
        .rating {
          color: white;
          font-weight: 600;
          margin-left: 5px;
          font-size: 13px;
        }
      }
    }
  }
}
