@use '~@amzn/awsui-design-tokens/polaris.scss' as awsui;

.rules-header {
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -0.64px;
    margin: 0;
}

.rules-sub-header {
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: -0.2px;
    margin: 0;
    color: #da8af6 !important;
}

.rules-campaign-not-started{
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: -0.2px;
    margin: 0;
}

.rules-description {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
}

.rule {
    max-width: 680px;
}

.rules-footer{
    display: flex; 
    justify-content: center;
}

.rules-container {
    width: 800px;
    background-color: awsui.$color-background-container-content !important;
    border: 1px solid #414d5c !important;
    padding: 0 10px 0 10px;
    position: relative; 
}

.rules-image-container {
    position: absolute;
    left: -160px; 
    top: 30%;
}

.rules-content-container {
    max-width: 800px;
    position: relative; 
    align-items: center;
}

.rules-component{
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    position: relative;
}

.rules-image{
    width: 0px;
    height: 0px;
}

@media(min-width: 1060px){
    .rules-image{
        transform: rotate(-.125turn);
        width: 220px;
        height: auto;
    }
}

  