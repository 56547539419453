.conversation {
  flex: 1;
  flex-direction: column;
  display: flex;
  a span.close-window {
    color: black;
  }
  .messages {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0px;

    -webkit-flex-grow: 1;
    -webkit-flex-shrink: 1;

    overflow: hidden;
    display: flex;
    padding: 0 16px;
    .messages-inner {
      overflow-y: scroll;
      padding-top: 16px;
      flex: 1;
      clear: both;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        width: 6px;
        border-radius: 4px;
        display: none;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 4px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 4px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
        border-radius: 4px;
      }
      .message {
        clear: both;
        .message-item {
          padding-bottom: 14px;
          .username {
            color: black;
            margin-bottom: 8px;
            font-size: 12px;
            .time {
              color: #5f6b7a;
            }
          }
          .message-text {
            background: #f4f4f4;
            border-radius: 15px;
            margin-right: 80px;
            padding: 10px;
            color: black;
            display: inline-block;
            font-size: 14px;
            word-wrap: break-word;
            // chat outer 380
            // 380px - 80px (margin) - 32px (padding)
            max-width: 268px;
            span > a {
              color: #539fe5 !important;
              &:hover {
                color: #132fe5 !important;
              }
            }
          }
          &.my-message {
            float: right;
            text-align: right;
            .message-text {
              text-align: left;
              background: #f6e4fd;
              margin-right: 0px;
              margin-left: 80px;
            }
          }
        }
      }
    }
  }
  .chat-footer {
    border-top: 1px solid #e9ebed;
    display: flex;
    align-items: center;
    .chat-input {
      flex: 1;
      display: flex;
      input {
        width: 100%;
        flex: 1;
        border: none;
        background: transparent;
        padding: 20px;
        font-size: 14px;
        color: #000716;
        &::placeholder {
          font-style: italic;
          color: #414d5c;
        }
        &:focus-visible {
          border: none;
          outline: none;
        }
      }
    }
    button {
      margin-right: 16px;
      border-radius: 4px !important;
    }
  }
}
