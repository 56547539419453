.feedback {
  .progress-bar {
      progress {
          height: 12px !important;
      }
      span {
          display: none;
      }
  }
  .feedback-section {
      display: flex;
  }
  .review-average {
      width: 10%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      span {
          font-size: 60px;
          font-weight: bold;
          margin-bottom: 10px;
      }
  }
  .review-percentage {
      width: 65%;
  }
}

.comments-wrapper {
  width: 75%;
  .comments-layout {
      .comment-divider {
          height: 1px;
          background-color: rgb(231, 229, 229);
          margin: 20px 0;
      }
  }
  button {
      width: 100%;
      display: flex !important;
      justify-content: center;
  }
}

.filter-wrapper {
  display: flex;
  width: 75%;
  margin: 70px 0 50px 0;
  justify-content: space-between;
  [data-testid=filter] {
      width: 100%;
  }
  [data-testid=select] {
      width: 200px;
  }
}