.comments-wrapper {
    width: 100%;
    .comments-layout {
      .comment-divider {
        height: 1px;
        background-color: rgb(231, 229, 229);
        margin: 20px 0;
      }
    }
    button {
      width: 100%;
      display: flex !important;
      justify-content: center;
    }
  }