@use '~@amzn/awsui-design-tokens/polaris' as awsui;
 
.faq-sidebar {
  div[class*='awsui_expandable-link-group'] {
    div[class*='awsui_header-navigation'] {
      a {
        font-size: 16px !important;
        font-weight: 700 !important;
        color: awsui.$color-text-body-default !important;
      }
    }
  }
  ul[class*='awsui_list-variant-expandable-link-group'] {
    padding-left: 18px !important;
  }
}