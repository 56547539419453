.awsui-visual-refresh {
  &.awsui-polaris-dark-mode {
    .public-events-container {
      .public-events-cards {
        background-color: #101b2a;
      }
    }
  }
  .public-events-footer {
      background-color: #000716 !important;
    }
  .public-events {
     div[class*='awsui_header-variant-full-page'] {
    display: none !important;
  }
  div[class^='awsui_card-inner'] {
    padding: 0 !important;

    .event-card {
      div[class^='awsui_content-wrapper'] {
        div[class^='awsui_content'] {
          padding: 0 !important;
          .event-card-content {
            padding: 20px;
            border-bottom: 1px solid #e9ebed;
            margin-bottom: 44px;
            height: auto !important;
          }
        }
      }
    }

    div[class^='awsui_card-header'] {
      position: absolute;
      z-index: 1;
      bottom: -3px;
      left: 0;
      right: 0;
      display: flex;
      align-items: flex-end;
      div[class^='awsui_card-header-inner'] {
        display: none !important;
      }
      div[class^='awsui_selection-control'] {
        position: relative !important;
      }
      &::after {
        position: absolute;
        left: 46px;
        top: 15px;
        font-size: 16px;
        font-weight: 400;
      }
    }
    div[class^='awsui_section'] {
      padding: 0 !important;
    }
    &::after {
      box-shadow: none !important;
    }
  }
    // overflow: hidden;
    max-height: calc(100vh - 30px - 56px);
    display: flex;
    flex-direction: column;
    .center-content {
      width: var(--awsui-default-max-content-width-y98gph);
      width: var(--awsui-default-max-content-width-y98gph);
      margin: 0 auto;
    }
    div[class*='awsui_layout'] {
      div[class*='awsui_content'] {
        display: flex;
        flex-direction: column;
        .public-events-container {
          &.no-events {
            display: flex;
            flex-direction: column;
            div.main-body {
              flex: 1;
              div.main-content {
                width: 800px;
                margin: 60px auto;
                text-align: center;
              }
            }
          }
          .catalog-title {
            padding-left: 150px;
          }
          .public-events-cards {
          min-height: 100vh !important;

            background-color: #ffffff;
            [data-fonttype='header-font'] {
              font-size: 100px !important;
            }
            .no-events-found {
              &__info {
                padding-left: 500px;
                padding-right: 500px;
              }
              &__background {
                margin-top: 200px;
                margin-bottom: -68px;
              }
            }
          }
        }
        .before-footer-img {
          img {
            width: 100vw;
            height: 353.352px;
            // flex-shrink: 0;
            object-fit: cover;
            object-position: top;
            display: block;
          }
        }

        
      }
    }
  }
}
