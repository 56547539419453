@use '~@amzn/awsui-design-tokens/polaris' as awsui;

.iconContainer {
  display: flex;
  height: 100%;
}

.inputIcon {
  display: inline-block;
  padding: 5px;
  border-radius: 3px;
  align-self: center;
  background-color: awsui.$color-background-control-disabled;
}
