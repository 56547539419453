$primary-color: orange;
.feedback-rating {
  display: inline-block;
  font-size: 22px;
  svg {
    width: 26px;
    height: 26px;
  }
  &.filled svg {
    fill: $primary-color !important;
    stroke: $primary-color !important;
  }
  &.unfilled {
    fill: none;
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

.feedback-rating-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
