.autoassignment-header {
  display: flex;
  align-items: center;
  padding-bottom: 30px;

  .autoassignment-image-container {
    width: 80%;
    text-align: center;

    img {
      transform: translateY(20px);
    }
  }
}

.autoassignment-jam-overview {
  display: flex;
  align-items: center;
  border: 1px solid #414D5C;
  border-radius: 10px;
  background-color: #000716;
  padding: 40px;
  transform: translateY(-30px);

  video {
    max-width: 640px;
  }

}