
$textFont: "Amazon Ember";
$whiteColor: #fff;
.container-custom {
    div[data-classname='join-an-event'] {
    button {
      margin-top: 20px;
    }
  }
  .description {
    color: $whiteColor;

    /* Body/Paragraph Regular */
    font-family: $textFont;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
  .text_secrets {
    color: var(--grey-125, #f8f8f8);

    /* Form/Bold */
    font-family: $textFont;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
    margin-top: 20px !important;
  }
  .form_field {
    width: 400px;
  }
  .text_blue {
    color: var(--blue-500, #539fe5);

    /* Body/Paragraph Regular */
    font-family: $textFont;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    margin-top: 6px;
    cursor: pointer;

  }
}

.locate-secret-key-modal {
  margin-right: 24px;
}