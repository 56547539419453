.event-slider-container {
  .slick-slide > div {
    margin: 10px;
  }
}

.slider {
  .slick-slide > div {
    > div {
      &:after {
        box-shadow: none !important;
      }

      > :nth-child(2) {
        border: 1px solid #a9a6a657;
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
        border-top: 0;
        width: auto !important;
      }
    }
  }
}

.slider-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  padding: 0 10px;

  .view-link {
    height: 100%;
    display: flex;
    align-items: center;

    a {
      font-size: 18px !important;
    }
  }

  .arrows-container {
    button:nth-child(1) {
      margin-left: 10px;
    }

    button:nth-child(1) {
      margin-right: 10px;
    }

    button {
      width: 33px;
      height: 33px;
      padding: 0;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border: none;
      cursor: pointer;
      background: #5f6b7a;

      span {
        svg {
          path {
            stroke: white !important;
          }
        }
      }

      &.disabled {
        background: #e9ebed;

        span {
          svg {
            path {
              stroke: #9ba7b6 !important;
            }
          }
        }
      }
    }
  }
}
