.team-stats-info {
  .stats-item {
    display: flex;
    justify-content: space-between;
  }
  .actions {
    display: flex;
    justify-content: center;
  }
}
