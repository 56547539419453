.awsui-visual-refresh {
  &.awsui-polaris-dark-mode {
    .booking-container {
      background-color: #232f3e;
    }
    div[data-classname='free-perks'] {
      background-color: #000716;
    }
    div[data-classname='free-subscription'] {
      color: #b99ce7 !important;
    }

    .expandables-container {
      background-color: #0F1B2A;
    }

  }
  .booking-container {
    margin-top: 10px;
    background-color: #e9ebed;
    padding: 1.5rem 0;
    border-radius: 8px;
  }

  .participants-input {
    width: 100px;
  }

  .aggrement-input {
    width: 150px;
  }

  .justify-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  $viewport-breakpoint-m: 1325px;
  @media only screen and (min-width: $viewport-breakpoint-m) {
    .custom-home__header-cta {
      z-index: 200;
      position: absolute;
      top: 0;
    }
  }

  .expandables-container {
    background-color: #eeeeee;
    padding: 3rem 0;
  }

  div[data-classname='free-perks'] {
    background-color: #e9ebed;
    padding: 10px 0;
    border-radius: 4px;
  }

  .inline {
    display: inline !important;

    &::after {
      content: ' ';
      margin-right: 0.5rem;
    }
  }
}
