@use '~@amzn/awsui-design-tokens/polaris' as awsui;

.markdown-instance {
    pre, code {
        background-color: awsui.$color-background-dropdown-item-hover;
    }

    code {
        color: awsui.$color-charts-palette-categorical-9;
    }

    pre code {
        color: awsui.$color-charts-red-1200 !important;
    }
}