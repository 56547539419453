

#language-description {
  max-width: 670px;
}
.form-space{
  max-width: 820px;
}
.update-btn{
  margin-top: 20px;
}
.link-style-none {
  text-decoration: none;
  color: inherit;
}
.link-color {
  color: var(--blue-500, #539FE5);;
}
.middle-btn{
  margin-top: -25px;
}
.qr-div{
  display: flex;
  width: 210px;
  height: 210px;
  flex-shrink: 0;
  padding: 10px;
  background-color: #fff;
}