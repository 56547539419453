.challenge-set-new {
  div[class^="awsui_scrolling-background"] {
    height: 246px !important;
    position: absolute !important;;
    z-index: 1 !important;;
    width: 100% !important;;
  }
  
  div[class^="awsui_container"] {
    z-index: 2 !important;
  }
  
  
  @media (min-width: 768px) and (max-width: 1049px) {
    div[class^="awsui_scrolling-background"] { 
      height: 250px !important;
    }
  }
  
  @media (min-width: 1049px) and (max-width: 1173px) {
    div[class^="awsui_scrolling-background"] { 
      height: 266px !important;
    }
  }
  
  @media (min-width: 1350px) {
    div[class^="awsui_scrolling-background"] { 
      height: 206px !important;
    }
  }
}
