@use "/src/styles/dark-mode.scss";

$challege-header-bottom-margin : 2rem;

.browse-challenges {
  margin-bottom: $challege-header-bottom-margin;

  .awsui-context-content-header {
    color: #fff;
    background-color: #fff !important;
    
    .table-header-title {
      color: #000716
    }

    @include dark-mode.enabled {
      background-color: #0f1b2a !important;
    }
  }

  .awsui-context-content-header input[type="search"] {
    background-color: #fff !important;
  }
}