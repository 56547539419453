// Small tablets and large smartphones (landscape view)
$screen-sm-max: 767;

// Small tablets (portrait view)
$screen-md-max: 991;

// Tablets and small desktops
$screen-lg-max: 1199;

// Xl screens
$screen-xl-max: 1599;

// Small devices
@mixin sm {
  @media (max-width: #{$screen-sm-max}px) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (max-width: #{$screen-md-max}px) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (max-width: #{$screen-lg-max}px) {
    @content;
  }
}

// Large devices
@mixin xl {
  @media (max-width: #{$screen-xl-max}px) {
    @content;
  }
}

// Custom devices
@mixin custom($screen) {
  @media (max-width: #{$screen}px) {
    @content;
  }
}
