.facilitator-messaging {
  .actions {
    display: flex;
    justify-content: space-between;
  }
  .dismiss-seconds {
    input {
      width: 80px !important;
      margin-right: 10px;
    }
    display: flex;
    align-items: center;
  }
}
