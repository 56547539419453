.awsui-visual-refresh {
  &.awsui-polaris-dark-mode {
    .avatar-popover {
      .avatar-popover-content {
        background-color: #000716 !important;
      }
      svg {
        fill: #000716 !important;
      }
    }
  }
  .meet-aws-jam-container {
    .intro-video-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .avatar-interact {
        display: flex;
        .avatar-popover {
          display: flex;
          align-items: center;
          height: 100%;
          max-width: 262px;
          margin-top: 30px;
          margin-right: -40px;
          .avatar-popover-content {
            background-color: #232f3e;
            padding: 14px;
            border-radius: 15px;
            color: #fff;
            div {
              font-weight: 700 !important;
            }
          }
          svg {
            fill: #232f3e;
          }
        }

        img.monkey-avatar {
          width: 248px;
          height: 274px;
          align-self: flex-end;
          margin-right: -20px;
        }
      }

      // .video-player {
      //   margin-top: -131px;
      //   border-radius: 8px;
      //   overflow: hidden;
      //   background-color: #2a2e33;
      //   @media (max-width: 660px) {
      //     > div {
      //       width: 480px !important;
      //       height: 280px !important;
      //     }
      //   }
      // }
    }
  }
}
