.awsui-visual-refresh {
  &.awsui-polaris-dark-mode {
    .meet-aws-jam {
      background-color: #000716 !important;

    }
  }
  .meet-aws-jam {
    .text-container {
      margin-top: 100px;
    }
    .meet-aws-jam-container {
      width: var(--container-width);
      margin: 0 auto;
      h1 {
        font-size: 42px !important;
        font-weight: 700 !important;
      }
      div[class^='awsui_restore-pointer-events'] {
        display: flex;
        justify-content: center;
      }
    }
  }
}
