.catalog-events {
    .catalog-title {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
        font-size: 32px; // TODO: this has no effect on the Header component
        // TODO: create custom Header component to allow 32px font size
    }
    .catalog-events__sortby {
        padding-bottom: 10px;
        max-width: 250px;
        button {
            background-color: #0e1b2a !important;
        }
    }
    div[class^='awsui_card-inner'] {
        padding: 0 !important;

        .event-card {
            > div {
                > :nth-child(2) {
                    border: 1px solid #a9a6a657;
                    border-bottom-left-radius: 16px;
                    border-bottom-right-radius: 16px;
                    border-top: 0;
                    width: auto !important;
                }
            }
        }

        div[class^='awsui_card-header'] {
            display: none;
        }
        div[class^='awsui_section'] {
            padding: 0 !important;
        }
        &::after {
            box-shadow: none !important;
        }
    }
    div[class^='awsui_footer'] {
        display: none;
    }
}
