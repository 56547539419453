.jam-settings-container {
  .jam-status {
    background-color: #a71111;
    padding: 3px 8px;
    border-radius: 4px;
    &.active {
      background-color: #006c09;
    }
    &.warning {
      background-color: #ff9900;
      color: black !important;
    }
    &.disabled {
      background-color: #414d5c;
    }
  }
  .time-expired {
    color: #ff9900;
    font-weight: bold;
  }
  div.substract-button {
    span[class*='awsui_content'] {
      display: flex;
      img.substract {
        margin-right: 8px;
        margin-top: 2px;
        filter: invert(0.4) sepia(1) saturate(5) hue-rotate(175deg);
      }
    }
  }
  div[data-classname='container'] {
    border-width: 0;
  }
}
