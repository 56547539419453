.awsui-visual-refresh {
  &.awsui-polaris-dark-mode {
    .iam-policy {
      .iam-dropzone {
        background-color: #0f1b2a !important;
      }
    }
  }
}

.iam-policy {
  .iam-dropzone {
    border-radius: '2px';
    border: 1px dashed #AAB7B8;
    background-color: '#FFF';
  }
}