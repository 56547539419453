.jam-messages {
  .new-label {
    padding: 3px 8px;
    background-color: #61e46a;
    font-size: 12px;
    color: #000716;
    border-radius: 4px;
  }
  div[class*='awsui_root'] {
    div[class*='awsui_content-wrapper'] {
      & > div[class*='awsui_header'] {
        background-color: transparent !important;
        .warning-label {
          color: #ffe457;
        }
        .info-label {
          color: #539fe5;
        }
      }
      div[class*='awsui_content-expanded'] {
        border-top: 1px solid #414d5c !important;
        padding-top: 15px;
      }
    }
    &::after {
      box-shadow: none !important;
    }
    &::before {
      border: 1px solid #414d5c !important;
    }
  }
}
