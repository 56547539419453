@use '~@amzn/awsui-design-tokens/polaris.scss' as awsui;

$bg: #0F1B2A;
$active-color: #539FE5;
$dropdown-height: 260px;
$dropdown-min-width: 700px;
$dropdown-width: 350px;

.topnav {
  position: relative;
  z-index: 1000;

  &_aside {
    position: absolute;
    right: awsui.$space-static-xxl;
    top: 50%;
    transform: translateY(-50%);
    height: inherit;
    display: flex;

    div[class^="awsui_button-dropdown"] {
      
      button {
        background-color: $bg !important;
        color: $active-color !important;
      }

      div[class^="awsui_dropdown"] {
        
        div [class^="awsui_dropdown-content"] {
          max-height: 300px !important;

          li {
            background-color: $bg !important;
            border: 2px solid transparent !important;
            border-radius: 0 !important;
            z-index: 0 !important;

            &:hover {
              color: $active-color !important;
            }

            &:first-child {
              padding-top: awsui.$space-static-xs !important;
              padding-bottom: awsui.$space-static-xs !important;
            } 
        
            &:not(:first-child, :last-child) {
              font-weight: 700 !important;
              font-size: 16px !important;
              line-height: 22px !important;
              padding: awsui.$space-static-xxs 0 !important;
            }

            &:nth-child(2) {
              border-top: 2px solid #7D8998 !important;
              padding-top: awsui.$space-static-xs !important
            }

            &:nth-last-child(2) {
              border-bottom: 4px solid #7d8998 !important;
              padding-bottom: awsui.$space-static-xs !important
            }

            a {
              padding-top: 0 !important;
            }

            &:last-child {
              display: flex;
              span {
                background-color: awsui.$color-background-button-primary-default;
                margin: awsui.$space-static-l 0 !important;
                margin-left:  awsui.$space-static-xl !important;
                border-radius: awsui.$border-radius-button;
                color: black !important;
                font-weight: 800;
              }
            }
          }
          
        }
      }
    }
  }

  header[class^="awsui_top-navigation"] {
    justify-content: flex-start !important;
    display: flex;
    background-color: $bg !important;

    div[class^="awsui_identity"] a {
      width: 100px;
      img {
        min-width: 35px !important; // it fixes the safari small logo showing issue
        max-width: 42px;
        max-height: awsui.$space-static-xl !important;
        margin-bottom: -8px;
      }

      span {
        font-weight: 400 !important;
      }
    }

    div[class^="awsui_utilities"] {
      div[class^="awsui_utility-wrapper"] {
        &:after {
          display: none !important;
        }

        span[class^="awsui_rotate"] {
          scale: 60%;
          margin-top: 4px;
        }
      }
    }

    div[class^="awsui_dropdown"] {
      transform: translateY(0px) !important;

      div[class^="awsui_dropdown-content-wrapper"] {
        background-color: $bg !important;
        // height: $dropdown-height;
        // min-width: $dropdown-min-width;
        width: $dropdown-width;

        // &::after {
        //   display: none;
        // }

        div[class^="awsui_ie11-wrapper"] {
          height: inherit;
        }

        div[class^="awsui_dropdown-content"] {
          height: inherit;
          max-height: unset !important;
          width: 100% !important;

          ul {
            display: inline-block;
            // grid-template-columns: repeat(1, minmax(270px, 1fr));
            // grid-template-rows: repeat(6, 1fr);
            // grid-auto-flow: column;
            // grid-column-gap: awsui.$space-static-xxl;
            // grid-row-gap: awsui.$space-static-s;
            height: inherit;
            padding: awsui.$space-static-xl !important;

            li {
              border: none  !important;
              margin-bottom: 16px;
              &:last-child:nth-child(3) {
                grid-row-start: 1;
              }

              &::after {
                content: attr(data-description);
                // position: absolute;
                // bottom: 0;
                color: #9BA7B6;
                font-weight: 400;
                font-size: 14px;
                // top: 30px;
              }

              [role="menuitem"] {
                font-weight: 700;
                font-size: 16px;
                line-height: 22px;
                padding-left: 0 !important;
              }
            }
          }

        }
      }

    }
  }

}


