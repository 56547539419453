.welcome-modal {
  div[class*='awsui_dialog'] {
    div[class*='awsui_container'] {
      border: 1px solid #414d5c !important;
      div[class*='awsui_header'] {
        padding-bottom: 0 !important;
        --size-icon-medium-6sroof: 16px !important;
        div[class*='awsui_content'] {
          padding-top: 0;
        }
      }
      > div[class*='awsui_content'] {
        padding-top: 0 !important;
        padding-bottom: 0 !important;

        .welcome-highlight {
          color: #da8af6 !important;
        }

        ul {
          padding-left: 26px;
        }

        .slide-center-img {
          display: flex;
          justify-content: center;
        }
        .slide-column-img {
          flex-direction: column;
          img.slide-2-img1 {
            width: 100%;
          }
        }
      }
      div[class*='awsui_header'],
      div[class*='awsui_footer'] {
        .welcome-modal-footer {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }
}
