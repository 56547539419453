.jam-challenge-status-modal-container {
  .text-dark {
    color: black !important;
  }

  .Loading-progress {
    width: 500px;
    height: 10px;
    border-radius: 3px;
    background: linear-gradient(90deg, #df2a5d 0%, yellow 15%, lime 30%, cyan 50%, blue 65%, magenta 80%, #df2a5d 100%);
    background-size: 200%;
    animation: moveGradient 5s linear infinite;
  }

  @media (max-width: 1060px) {
    .Loading-progress {
      width: 300px;
    }
  }
  @media (max-width: 900px) {
    .Loading-progress {
      width: 200px;
      margin-bottom: 16px;
    }
  }

  @keyframes moveGradient {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -200% 0%;
    }
  }

  .content-center {
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 760px) {
      flex-direction: column;
    }
    .lab-status-modal-info {
      max-width: 534px;
    }
    .lab-status-modal-image {
      display: flex;
      margin-left: 16px;
      @media (min-width: 1300px) {
        padding-right: 200px;
      }
    }
  }

  .lab-status-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 0 60px;
  }
}

.yellow-bg {
  background: #ffe457 !important;
}

.green-bg {
  background: #61e46a !important;
}

.cross-bar {
  position: absolute;
  left: 97%;
  top: -4%;
}
