$primary-color: #3291e9;
.review-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
        color: #3291e9;
        text-decoration: underline;
    }
    .reviews-wrapper {
        display: flex;
        width: 130px;
        margin: 0 20px;
        justify-content: center;
    }
}

.progress-container {
    width: 85%;
    height: 14px;
    background-color: #f3f3f3;
    position: relative;
    border-radius: 10px;
}

.progress-bar {
    height: 100%;
    position: absolute;
    background-color: $primary-color;
    text-align: center;
    line-height: 30px;
    color: white;
    border-radius: 10px;
}