@import '~@amzn/awsui-design-tokens/polaris.scss';

$challenge-count-color: #76869a;

.challenge-ordering-container {
  position: relative;
  padding-top: $space-static-xxxl;

  .challenge-alert {
    position: absolute;
    top: -40px;
  }

  .challenge-count {
    color: $challenge-count-color;
    font-weight: 500;
  }
}

.challenge-ordering-list {
  counter-reset: count 0;
  min-height: 100px;
}
