.awsui-visual-refresh {
  &.awsui-polaris-dark-mode {
    .challenge-translation {
      .resources-dropzone {
        background-color: #0f1b2a !important;
      }
    }
  }
}

.challenge-translation {
  .resources-dropzone {
    border-radius: '2px';
    border: 1px dashed #aab7b8;
    background-color: '#FFF';
  }
  div[class^='awsui_content'] {
    > div[class^='awsui_wrapper'] {
      overflow: visible !important;
      > table[class^='awsui_table'] {
        tr[class^='awsui_row'] {
          // when we click 3 dots in UI popup menu hides due to the overflow property in the table, to fix that we are manupulating through the below css
          td[class^='awsui_body-cell'] {
            overflow: visible !important;
          }
        }
      }
    }
  }

  .expandable-section {
    div[class^='awsui_root'] {
      box-shadow: none !important;
    }
    div[class^='awsui_content-wrapper'] {
      background-color: #f4f4f4;
      border-radius: 15px;
    }
    div[class^='awsui_header'] {
      background-color: #f4f4f4;
      border-radius: 15px;
    }
  }
}
