@use '~@amzn/awsui-design-tokens/polaris' as awsui;

.jam-chat-container {
  &.chat-modal {
    position: absolute;
    bottom: 28px;
    right: 35px;
    .chat-outer {
      height: 550px;
      width: 380px;
      position: absolute;
      right: 0;
      bottom: 78px; // bottom 30px + button heights 48px
    }
  }
  &.fullscreen {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 150px);
    justify-content: center;
    margin-top: 16px;
    .message-text {
      max-width: none !important;
    }
  }
  .chat-outer {
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex: 1;
    border: 1px solid awsui.$color-border-button-normal-active;
    box-shadow: -2px 2px awsui.$color-border-button-normal-default;

    .chat-header {
      &.support-header {
        background: #354150;
        h3 {
          color: white !important;
        }
        button.minimize {
          filter: invert(1);
        }
      }
      height: 58px;
      border-bottom: 1px solid #e9ebed;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      h3 {
        color: #000716 !important;
        font-size: 18px !important;
      }
      span > svg {
        color: #414d5c;
      }
      button.minimize {
        background: transparent;
        border: none;
        padding: 8px 6px;
        cursor: pointer;
        img {
          filter: invert(0.7);
        }
      }
    }
    .chat-header-outer {
      button {
        border: none !important;
        border-radius: 4px !important;
        &[data-classname='danger'] {
          background-color: #a71111 !important;
        }
        &[data-classname='info'] {
          background-color: #232f3e !important;
        }
        &[data-classname='back'] {
          span {
            color: #0972d3 !important;
          }
        }
        span {
          color: white !important;
        }
      }
    }
    .action-bar {
      background: #e9ebed;
      display: flex;
      flex-direction: row;
      padding: 10px;
      .flexed {
        flex: 1;
      }
    }
    .chat {
      flex: 1;
      flex-direction: column;
      display: flex;
    }
    button.supportchat-item {
      background-color: transparent;
      border-top: none !important;
      border-left: none !important;
      border-right: none !important;
      border-bottom: 1px solid #e9ebed;
      text-align: left;
      padding: 16px;
      display: flex;
      flex-direction: row;
      cursor: pointer;
      span {
        color: #000716 !important;
        font-size: 14px;
        font-weight: 700;
        &.chat-title {
          display: block;
          flex: 1;
        }
        &.counter {
          background-color: #b30000;
          height: 18px;
          min-width: 18px;
          border-radius: 18px;
          font-size: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: white !important;
          margin-right: 8px;
        }
      }
    }
  }
  .chat-buttons {
    display: flex;
    justify-content: flex-end;
    button {
      height: 48px;
      background: #000 !important;
      border-color: white !important;
      border-radius: 24px !important;
      &[data-classname='support'] {
        background: #354150 !important;
      }
      > span {
        display: flex;
        justify-content: center;
        span {
          color: white !important;
        }
      }
      .unread-counter {
        height: 24px;
        width: 24px;
        background-color: #b30000;
        color: white;
        display: flex;
        border-radius: 24px;
        justify-content: center;
        align-items: center;
        margin-left: 5px;
        margin-top: -2px;
      }
    }
  }
}
