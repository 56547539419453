@mixin enabled {
    body.awsui-polaris-dark-mode & {
        @content;
    }

    :global(body.awsui-polaris-dark-mode) & {
        @content;
    }
}

@mixin disabled {
    body.awsui-polaris-light-mode & {
        @content;
    }

    :global(body.awsui-polaris-light-mode) & {
        @content;
    }
}