.event-detail-container {
    div[class^="awsui_content"] {
        padding-top: 0 !important;
    }
    
    .action-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 380px;
    }
    .challenges {
        margin: 20px 0;
    }
    .challenge-total {
        color: rgb(121, 120, 120);
        font-weight: 500;
    }
}