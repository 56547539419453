.choose-learning-event {
  div[class*='awsui_header-variant-full-page'] {
    display: none !important;
  }
  div[class^='awsui_card-inner'] {
    padding: 0 !important;

    .event-card {
      div[class^='awsui_content-wrapper'] {
        div[class^='awsui_content'] {
          padding: 0 !important;
          .event-card-content {
            padding: 20px;
            border-bottom: 1px solid #e9ebed;
            margin-bottom: 44px;
            height: auto !important;
          }
        }
      }
    }

    div[class^='awsui_card-header'] {
      position: absolute;
      z-index: 1;
      bottom: -3px;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      display: flex;
      align-items: flex-end;
      width: 100% !important;
      div[class^='awsui_card-header-inner'] {
        display: none !important;
      }
      div[class^='awsui_selection-control'] {
        position: relative !important;
        width: 100% !important;
        height: 100% !important;
        > label {
          justify-content: flex-start !important;
          padding-left: 20px;
          z-index: 1;
          cursor: pointer;
          display: flex;
          align-items: flex-end !important;
          padding-bottom: 16px !important;
        }
      }
      &::after {
        content: 'Select';
        position: absolute;
        left: 46px;
        bottom: 13px;
        font-size: 16px;
        font-weight: 400;
      }
    }
    div[class^='awsui_section'] {
      padding: 0 !important;
    }
    &::after {
      box-shadow: none !important;
    }
  }
  .match-count {
    display: flex;
    height: 100%;
    align-items: center;
  }
}
