.my-jam-footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 1000;
  background: #000716;
  padding: 1.5rem;
  height: 52px;
  a[data-classname='presenter-mode'] {
    display: flex !important;
    img {
      margin-right: 8px;
    }
  }
}
