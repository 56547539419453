.awsui-visual-refresh {
  &.awsui-polaris-dark-mode {
    .clickable-profile-option {
      [data-testid='link-container'] {
        [data-colorid='container-header'] {
          color: #539fe5 !important;
        }
      }
    }
  }
  .clickable-profile-option {
    [data-testid='link-container'] {
      cursor: pointer !important;
      height: 180px;
      [data-colorid='container-header'] {
        color: #0873d3 !important;
        font-size: 20px !important;
        margin-top: 10px;
      }
    }
  }
}
