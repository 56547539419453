.feedback-progress-container {
  .divider {
    border-bottom: 1px solid #a9a6a657;
    margin-bottom: 8px;
  }

  .custom-progress {
    .awsui_percentage-container_11huc_1mu7f_279 {
      display: none;
    }

    .awsui_progress_11huc_1mu7f_185,
    .awsui_progress_11huc_1mu7f_185::-webkit-progress-value {
      height: 10px !important;
      border-radius: 10px !important;
    }
  }

  .fixed-width {
    width: 225px;
  }
}
