@import '~@amzn/awsui-design-tokens/polaris.scss';

.table-header-title {
  display: flex;
  align-items: center;
}

.table-header-counter {
  color: var(--color-text-counter-wyou6g, #687078);
  font-weight: 400;
  margin-left: 0.5rem;
  margin-right: 1.5rem;
}