#specificTileId {
  display: none;
}
#specificTileId-label > div:first-child{
  margin-bottom: 0!important;
}

.input-width-limit {
  max-width: 66.66%;
}

.event-time_checkbox-wrapper {
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.5em;
  margin-top: -0.2em
}

.numeric_input_style {
  vertical-align: middle;
}