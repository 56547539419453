@use '/src/styles/media.scss' as media;

$circle-size: 16px;
$spacing: clamp(0.25rem, 2vw, 0.5rem);

.timeline {
  display: flex;
  padding: 0;
}

.item {
  display: grid;
  grid-template-columns: min-content auto;
  grid-template-rows: min-content 1rem;
  position: relative;
  align-items: center;
  flex-grow: 1;
}

.active {
  .itemTitleText {
    color: #219b02;
  }

  &:before {
    border: 2px solid #219b02;
  }
}

.cancelled {
  .itemTitleText {
    color: #d13212;
  }

  &:before {
    border: 2px solid #d13212;
  }
}

.itemIcon {
  grid-row: 1;
  grid-column: 1;
  margin: 0 0.5rem;
}

.circleNew {
  border-radius: 50%;
  width: $circle-size;
  height: $circle-size;
  padding: 0px;
  border: 2px solid #545b64;
  color: rgba(0, 0, 0, 0);
  text-align: center;
  font-size: 12px;
  font-weight: normal;
  line-height: 17px;
}

.itemTitle {
  display: flex;
  align-items: center;
}

.itemTitleText {
  margin: 0;
  font-size: 14px;
}

.itemSubtitle {
  grid-row: 2;
  grid-column: 2;
  display: flex;
  font-size: small;
  margin-left: 27px;
  align-self: start;
  flex-wrap: wrap;
}

.itemSubtitleText {
  margin-right: 5px;
}

.itemSubtitleText,
.itemSubtitleDate {
  width: max-content;
}

.flex-item {
  display: flex;
  align-items: center;
}

.itemHorizontalBar {
  height: 2px;
  background: var(--color-text-heading-secondary-2r0p61, #545b64);
  flex-grow: 1;
  margin: 0 3px;

  .item:last-child & {
    display: none;
  }
}

.itemVerticalBar {
  width: 2px;
  background: var(--color-text-heading-secondary-2r0p61, #545b64);
  flex-grow: 1;
  margin: 3px 0;
  display: none;

  .item:last-child & {
    display: none;
  }
}

@include media.lg {
  .timeline {
    flex-direction: column;
  }

  .item {
    grid-template-rows: min-content 4rem;
  }

  .itemHorizontalBar {
    display: none;
  }

  .itemVerticalBar {
    display: block;
    height: 100%;
    margin: 0 auto;
  }
}
