@use '~@amzn/awsui-design-tokens/polaris' as awsui;

.awsui-visual-refresh {
  &.awsui-polaris-dark-mode {
    .real-reviews-container {
      background-color: #000716 !important;
    }
  }
  .footer-links-container {
    background-color: #000716 !important;
  }

  .spinner-homepage {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 400px;
  }

  .home-page-container {
    height: 100%;
    position: relative;
    --container-width: 87%;
    overflow-x: hidden;
    
    .home-cover__outer {
      overflow: hidden;
      position: relative;
      display: flex;
      background-position: center;
      @media (max-width: 960px) {
        flex-direction: column;
      }
    }

    .home-cover {
      display: block;
      padding-left: 4%;
      flex: 1;

      @media (max-width: 960px) {
        &__caption {
          display: none;
        }
        text-align: center;
        .text-container {
          width: auto !important;
          .header-cover__sub-title {
            font-size: 24px !important;
          }
        }
      }

      .text-container {
        color: awsui.$color-text-home-header-default;
        margin: 0 7%;
        padding: 60px 0 0;
        width: 528px;
        display: flex;
        flex-direction: column;

        button {
          background: transparent !important;

          span[class*='awsui_icon'] {
            color: #d1d5db !important;
          }
        }

        [data-testid='primary-button'] {
          background-color: orange !important;
          border: 1px solid orange !important;
          color: #000716 !important;
          margin-right: 10px;
        }

        [data-testid='secondary-button'] {
          border: 2px solid #539fe5 !important;
          color: #539fe5 !important;
        }

        .home-cover__header-caption {
          display: inline-block;
          max-width: 528px;
        }

        .header-cover__sub-title {
          font-size: 40px;
          line-height: 1.1;
        }

        .home-cover__category,
        .home-cover__header-caption {
          color: awsui.$color-text-home-header-default;
        }
      }
    }
    .homepage-hero {
      display: flex;
      align-items: flex-end;
      padding-right: 4%;
      padding-top: 40px;
      --hero-image-width: 583px;
      &__image {
        width: var(--hero-image-width);
        height: calc(var(--hero-image-width) * 0.56);
        background-size: contain;
        background-repeat: no-repeat;
      }

      @media (min-width: 1100px) and (max-width: 1200px) {
        --hero-image-width: 500px;
      }
      @media (min-width: 960px) and (max-width: 1100px) {
        --hero-image-width: 450px;
      }
      @media (max-width: 960px) {
        --hero-image-width: 583px;
        justify-content: center;
        padding-top: 0;
        padding-right: 0;
        padding-left: 4%;
      }
      @media (max-width: 600px) {
        --hero-image-width: 400px;
      }
    }

    .discover-events {
      width: var(--container-width);
      margin: 0 auto;

      .beginner-event-slide {
        margin: 20px 0;
      }

      .discover-events-title {
        text-align: center;
        font-size: 42px !important;
        margin: 0 0 10px;
      }

      .discover-events__browse-all {
        text-align: center;
        margin-bottom: 60px;
      }
    }

    .meet-aws-jam {
      background-color: #f2f3f3;
    }

    .book-event {
      width: var(--container-width);
      margin: 0 auto;
      margin-top: 50px;
    }
    .faqs-container,
    .satisfied-clients-logos {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 60px 7%;
    }

    .satisfied-clients-logos {
      margin: 50px 0 40px;
      padding: 45px 7%;
    }

    .real-reviews-container {
      background-color: #f8f8f8;
      .real-reviews {
        margin: 0 auto;
        width: var(--container-width);
        padding: 20px 0 60px;
        h1 {
          font-weight: 700 !important;
          font-size: 42px !important;
        }
      }
    }

    .footer-links-container {
      background-color: #f8f8f8;
      .real-reviews {
        margin: 0 auto;
        width: var(--container-width);
        padding: 20px 0 60px;
        h1 {
          font-weight: 700 !important;
          font-size: 42px !important;
        }
      }
    }
  }

  .modal__youtube-player {
    display: flex;
  }
}
