.lab-events-header-container {
  display: flex;
  align-items: center;
  margin: 12px 0;

  .lab-events-header{
    flex-grow: 1
  }
  .lab-events-date-select {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
  }
}

.labs-date-range-input {
  min-width: 350px !important;
}