.footer-links {
    padding-left: 100px;
    padding-bottom: 50px;
    .section {
        &__title {
            font-size: 20px;
            padding-bottom: 10px;
            color: white !important;
        }
        &__item{
            &__title{
                font-size: 16px;
                padding-bottom: 5px;
                [data-testid='link-button'] {
                    color: white !important ;
                  }
            }
            &__description{
                margin-top: -5px;
                padding-bottom: 15px;
                color: #d1d5db !important ;
            }
        }
    }
}