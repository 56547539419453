@import '~@amzn/awsui-design-tokens/polaris.scss';

.detail-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $space-static-s 0;
  
  button {
    white-space: nowrap !important;
  }
}
