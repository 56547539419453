#my-captcha-box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  background: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  display: none; /* Hide by default */
}

#my-captcha-box.active {
  display: block; /* Show when active */
}

.captcha-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9998;
  display: none; /* Hide by default */
}

.captcha-overlay.active {
  display: block; /* Show when active */
}
