@use '@amzn/awsui-design-tokens/polaris.scss' as awsui;
@import './header';
@import './aws-colors.scss';

// https://www.a11yproject.com/posts/2013-01-11-how-to-hide-content/
.custom-visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.awsui-polaris-dark-mode {
  .awsui-table-header,
  .inline-header,
  .awsui-cards-header {
    color: $color-text-heading-default !important;
  }
}

.right-border {
  border-right: 1px solid $color-border-status-warning;
}

.left-border {
  border-left: 1px solid $color-border-status-warning;
}

.inline {
  display: inline-block !important;
}

.secondary-text {
  color: $color-text-form-secondary;
}

.link {
  text-decoration: none;
  color: $awsBlueLightAlt2;
}

.dropdown-select {
  width: 200px;
  display: inline-block;
}

.aws-orange-info {
  color: $awsOrange !important;
}

.aws-positive-green {
  color: $color-text-status-success;
}

.date-label {
  float: left;
  margin-right: 5px;
  line-height: 30px;
}

.date-selector {
  width: 215px !important;
  max-width: 400px !important;
  div:first-of-type {
    width: 15em;
  }
}

.button-link {
  border: none;
  color: $primaryWhite;
  background-color: transparent;
  outline: none;
}

.button-link:focus {
  border: none;
}

.table-divider {
  border-right: 1px solid $color-border-divider-default;
}

.container-table-header {
  background-color: $color-background-container-header;
  margin: 0;
  padding: 0 20px 10px 0px;
  border-bottom: 1px solid $color-border-divider-default;
}

.container-table-row {
  border-bottom: 1px solid $color-border-divider-default;
}

.input-icon {
  display: inline-block;
  padding: 5px 5px 5px 10px;
  border-radius: 3px;
  margin-left: -5px;
}

.awsui-visual-refresh {
  &.awsui-polaris-dark-mode {
    .hover-reveal,
    .hover-reveal > div > input {
      text-shadow: 0 0 9px rgba(255, 255, 255, 0.5) !important;
    }
  }
  .hover-reveal,
  .hover-reveal > div > input {
    color: transparent !important;
    text-shadow: 0 0 9px rgba(0, 0, 0, 0.5) !important;
  }
  .hover-reveal:hover,
  .hover-reveal > div > input:hover {
    color: inherit !important;
    text-shadow: none !important;
  }
}

.hover-icon {
  color: $color-text-body-default !important;
}

.default-text {
  color: $color-text-body-default !important;
}

.table-row-border {
  border-top: 1px solid $color-border-divider-default;
  margin: 10px -20px;
  padding: 10px 20px;
}

.ml-negative-5 {
  margin-left: -5px;
}

.zero-margin-bottom {
  margin-bottom: 0px !important;
}

.ml-5 {
  margin-left: 5px;
}

.mr-5 {
  margin-right: 5px;
}

.mt-4 {
  margin-top: 4px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-22 {
  margin-top: 22px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-20 {
  margin-bottom: 20px;
}

.pr-12 {
  padding-right: 12px;
}

.mb-10 {
  margin-bottom: 10px;
}

.section-first-row {
  margin-top: -20px;
  margin-bottom: -24px;
  padding-top: 18px;
  padding-bottom: 18px;
}

.inline,
.inline-select {
  display: inline-block !important;
}

.inline-select {
  margin-left: -2px;
}

.text-input,
.email-input {
  width: 400px;
}

.numeric-input {
  display: inline-block !important;
  max-width: 60px;
}

.numeric-long-input {
  display: inline-block !important;
  max-width: 85px;
}

.time-input {
  max-width: 150px;
}

.time-input-small {
  max-width: 96px;
}

.time-input-tile {
  margin-left: -22px;
  width: 150%;
}

.grey-section-divider-top {
  padding: 18px 25px 12px 20px;
  margin: 20px -24px -24px -20px;
  border-top: 1px solid $color-border-divider-default;
}

.gray-box {
  background-color: $color-border-divider-default;
}

.primary-btn {
  background-color: $color-background-button-primary-active;
  color: $color-text-button-primary-active;
  padding: 8px;
  font-family: $font-family-base;
  font-weight: bold;
  cursor: pointer;
}

.button-right {
  float: right;
}

.float-left {
  float: left;
}

.force-overlap {
  margin-left: -2px;
}

.grid-striped {
  .grid-row {
    margin: 0px -20px 0px -20px;
    padding: 10px 20px 10px 20px;
  }
  .grid-row:nth-child(even) {
    background: $color-background-control-disabled;
  }
  .grid-row:last-child {
    margin-bottom: -20px;
  }
}

.overlap-card {
  z-index: 200;
  position: absolute;
  top: 216px;
  min-width: 235px;
}

.documentation-card {
  height: 326px;
  ul > li {
    list-style-type: none;
  }

  ul > li > a {
    text-decoration: none !important;
  }
}

.warning {
  color: $color-text-status-error !important;
}

.dark-background-label {
  // Static value of label color from Polaris, don't want it to switch
  // because this is the desired contrast on light and dark mode
  color: #d5dbdb;
}

.qr-reader-margin-top {
  margin-top: -28px;
}

.game-board-box {
  background-color: awsui.$color-background-layout-main;
}

.game-board-card,
.game-board-challenge-card {
  background-color: $color-background-container-content;
  border-radius: 4px;
  h1 {
    text-align: center !important;
    color: $color-text-accent;
  }
}

.game-board-challenge-card {
  min-height: 208px;
  .game-board-card-content {
    margin-top: 24px;
  }
}

.max-form-width {
  max-width: 752px;
}

.import-challenge-select {
  max-width: 460px;
  min-width: 460px;
}

.inline-event-select > div {
  max-width: 460px;
  min-width: 460px;
  display: inline !important;
  margin-top: 22px;
}

.long-select-input {
  min-width: 360px;
  max-width: 500px;
}

.team-metric-key-value {
  min-width: 13rem;
}

.slug {
  background-color: $color-background-item-selected !important;
  height: 20px;
  max-width: 392px;
  padding: 4px;
}

.custom_breadcrumb_color {
  .awsui_breadcrumb_1kosq_42ng7_97 > .awsui_anchor_1kosq_42ng7_104 {
    color: #e9ebed !important;
  }
}

:root {
  iframe#webpack-dev-server-client-overlay {
    display: none !important;
  }
}

span[class*='awsui_label-wrapper'] {
  span[class*='awsui_radio-control'] {
    cursor: pointer !important;
    input {
      cursor: pointer !important;
    }
  }
}

.nested-interactive[aria-hidden='true'] {
  display: none;
}

.align-items-center {
  align-items: center;
}

.cursor-pointer {
  cursor: pointer;
}
