$color-team: #6237a7;
$color-individual: #06645a;

span[class^='awsui_badge'] {
  &[data-learning-type='team' i] {
    background-color: $color-team !important;
  }
  &[data-learning-type='individual' i] {
    background-color: $color-individual !important;
  }
}
