.event-labs {
  .progress-meter {
    progress {
      --progress-color: #539fe5;
      &.error {
        --progress-color: red;
      }
      color: var(--progress-color);
      &::-moz-progress-bar {
        background: var(--progress-color) !important;
      }
      &::-webkit-progress-value {
        background: var(--progress-color) !important;
      }
    }
  }
}
