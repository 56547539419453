.global-value {
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: -0.6399999856948853px;
  text-align: left;
  color: #da8af6 !important;
}

.circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.severity-container-0 {
  border-radius: 4px;
  background: rgba(255, 33, 33, 0.1);
  padding: 2px 10px;

  .severity-0 {
    color: #29ad32 !important;
  }

  .severity-bg-0 {
    background: #29ad32;
  }
}

.severity-container-1 {
  border-radius: 4px;
  background: rgba(236, 114, 17, 0.1);
  padding: 2px 10px;

  .severity-1 {
    color: #ec7211 !important;
  }

  .severity-bg-1 {
    background: #ec7211;
  }
}

.severity-container-2,
.warm-up {
  .severity-bg-2 {
    background: #ff2121;
  }

  .severity-2 {
    color: #ff2121 !important;
  }
}

.challenge-card-footer div:last-child {
  margin-left: auto;
}

.solved-challenge {
  border-radius: 4px;
  background: #414d5c;
  color: #ffffff !important;
  padding: 2px 8px;
  font-size: 12px !important;
  position: absolute;
  top: -10px;
  right: 20px;
  z-index: 999;
  font-weight: bold !important;
}

.pos-relative {
  position: relative;
}

.sponsor-info {
  background: #414d5c;
  color: #ffffff !important;
  padding: 1rem;
  border-radius: 15px 0 0 15px;
  width: 125px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.sponsor-info > .content {
  font-weight: 400 !important;
  font-size: 12px !important;
}

.challenge-container {
  padding: 2rem;
  width: -webkit-fill-available;
}

.has-sponsor {
  margin-left: -60%;
}

.card-selected {
  border: 2px solid #539fe5;
}

.solved {
  border: 2px solid #61e46a;
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-grow {
  flex-grow: 1;
}

.locked-badge {
  display: flex;
  align-items: center;
  background: #9ba7b6;
  padding: 3px 8px 3px 8px;
  border-radius: 4px;
  color: #000 !important;
}

.card-badge {
  display: flex;
  align-items: center;
  background: #61e46a;
  padding: 3px 8px 3px 8px;
  border-radius: 4px;
  color: #000 !important;
}

.text-dark {
  color: #000 !important;
}

.challenge-detail-card-container {
  cursor: pointer;
}

.awsui-visual-refresh {
  &.awsui-polaris-dark-mode {
    .dark-bg {
      background: #000716 !important;
      border: 1px solid #414d5c;
    }

    .gradient-bg > main {
      background: radial-gradient(100% 100% at 100% 100%, #9963b4 0%, rgba(51, 27, 112, 0) 100%);
    }

    .solved {
      border: 2px solid #61e46a;
    }

    .card-selected {
      border: 2px solid #539fe5;
    }

    .awsui_header_14iqq_1bz6y_222 {
      background: #000716 !important;
    }
  }
}
