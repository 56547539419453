.coloured {
  background: linear-gradient(90deg, #da8af6 0%, #5cb1ff 100%) !important;
  color: #000716 !important;
  border: none !important;
  padding: 6px 22px !important;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;

  .button-text {
    font-size: 14px;
    font-weight: 700 !important;
    color: #000716 !important;
  }
}

.status-COMPLETE {
  color: #61e46a !important;
}

.status-INCOMPLETE {
  color: #e0ca57 !important;
}

.text-grid {
  background: #414d5c;
  width: 1px;
  height: 32px;
  text-align: center;
}

.light-violet {
  color: #da8af6 !important;
}

.clue-container {
  display: flex;
  justify-content: space-between;
  border-top: 2px solid #414d5c;
  padding: 1.5rem 0;
}

.task-card-container {
  cursor: pointer !important;
}
