.awsui-visual-refresh {
  &.awsui-polaris-dark-mode {
    div[data-classname='container'] {
      background-color: #000716 !important;
    }
    div[data-classname='container-equal-height'] {
      background-color: hsl(221, 100%, 4%) !important;
      min-height: 100%;
    }
  }
  div[data-classname='container'] {
    background-color: white !important;
    border-radius: 15px;
    border: 2px solid #414d5c;
    &::after {
      box-shadow: none !important;
    }
  }
  div[data-classname='container-equal-height'] {
    background-color: white !important;
    border-radius: 15px;
    border: 2px solid #414d5c;
    min-height: 100%;
    &::after {
      box-shadow: none !important;
    }
  }
}
