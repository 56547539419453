@use '~@amzn/awsui-design-tokens/polaris' as awsui;
.awsui-visual-refresh {
  &.awsui-polaris-dark-mode {
    .review-card {
      background-color: #232f3e !important;
    }
  }

  .review-card {
    display: flex;
    flex-direction: column;
    border: 1px solid #a9a6a657;
    margin: 0 8px;
    border-radius: 8px;
    padding: 30px;
    height: auto;
    min-height: 210px;
    background-color: #fff;
    &__image-container {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      width: 100%;
      height: 100%;
    }
    &__image {
      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      margin-right: 10px;
    }
    &__text-container {
      display: flex;
      flex-direction: column;
    }
    &__text {
      font-size: 14px;
      font-weight: bold;
    }
    &__comment {
      font-size: 14px;
      margin-top: 4px;
    }
    button {
      font-weight: normal !important;
      padding: 0 10px !important;
    }
  }
}
