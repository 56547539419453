.dropzone {
  border-radius: 5px;
  border: 2px dashed rgb(172 178 184);
  border-image: none;
  max-width: 550px;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .icon{
    color: #0872d3;
    font-weight: 500;
  }
  .drag-n-drop {
      label {
          color: #0872d3;
          font-weight: 500;
          text-decoration: underline;
      }
  }
  .types {
      color: #a7b3c3cc;
  }
}