@import '~@amzn/awsui-design-tokens/polaris.scss';

#footer {
  bottom: 0;
  padding: 0.5rem;
  margin: 0;
  min-height: 18px;
  font-family: 'Amazon Ember', 'Helvetica Neue', Roboto, Arial, sans-serif;
  background-color: #232f3e;
  color: $color-text-home-header-default;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & .container .footerItem a {
    color: $color-text-home-header-default;
    min-width: 50px;

    &:hover {
      color: $color-text-accent;
    }
  }
}

.container {
  text-align: center;
  padding-right: 2rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.footerItem {
  &:not(:last-child):after {
    content: '|';
    margin: 0 1rem;
  }
}

.cookiePreferences {
  cursor: pointer;
}

.roleSpoofing {
  float: right;
  cursor: pointer;
}

#copyright {
  color: $color-text-home-header-secondary !important;
  margin-top: 2px;
}

.footerEnd {
  display: flex;
  flex-direction: row;
  align-items: center;
}
