.jam-team-container {
  .countdown {
    color: #da8af6;
  }
  .leaderboard-link {
    display: inline;
    margin-left: 6px;
    span {
      height: 10px !important;
      margin-top: 10px;
      svg {
        height: 14px !important;
        width: 10px !important;
      }
    }
  }
}
