@use '@amzn/awsui-design-tokens/polaris.scss' as awsui;

.date-selector-filter {
  display: inline;
  float: right;
  width: auto !important;
}
.date-selector-action-button {
  width: 350px;
  display: inline-block;
}
.multi-select-container {
  margin-left: 0px;
  display: flex;
  white-space: nowrap;
  float: right;
}
.text-filter {
  width: 300px;
  display: block;
}

.event-list-checkbox-filter {
  display: flex;
  height: 100%;
  justify-items: start;
  align-items: center;
}

@media screen and (max-width: 1224px) {
  .multi-select-container {
    margin-left: -10px;
    width: 100%;
    .inline {
      width: 100% !important;
      div {
        width: 100% !important;
        margin-left: 0px !important;
      }
      div[class^='_checkbox'] {
        width: 5% !important;
      }
    }
  }
  .text-filter {
    width: 100%;
  }
}

.awsui_dropdown_qwoo0_y8h25_93 {
  z-index: 999 !important;
}

.event-list-select {
  // hide the dropdown cloud with close icon
  .awsui_root_dm8gx_i7pg9_97 {
    display: none;
  }
}

span[data-classname='change-requested'] {
  background: #6f062f !important;
}
