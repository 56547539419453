.awsui-visual-refresh {
  &.awsui-polaris-dark-mode {
    .public-event-card {
      border: 1px solid #414d5c !important;
      border-radius: 17px;
    }
  }

  .public-event-card {
    border: 1px solid #d1d5db !important;
    border-radius: 17px;
    &__container {
      cursor: pointer;
      text-decoration: none;
    }
    &__image {
      cursor: pointer;
    }
    &__content {
      cursor: pointer;
    }
  }
}
