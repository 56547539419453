@import '~@amzn/awsui-design-tokens/polaris.scss';


$grey-200: #E9EBED;
$fundamental-bg: #29AD32;
$intermediate-bg: #EB5F07;
$advanced-bg: #23379B;
$expert-bg: #9469D6;

.challenge-ordering-listitem {
  margin: $space-static-s 0;
  counter-increment: count 1;

  
  &.gu-mirror { //active state
    .position::after {
      content: '-' !important;
    }
  }

  .content {
    padding: $space-static-l;
    display: flex;

    .position {
      display: flex;
      align-items: center;
      padding-right: $space-static-xl;
      border-right: 2px solid $grey-200;

      &::after {
        content: counter(count);
        font-weight: 800;
        font-size: 20px;
        padding-left: $space-static-m;
      }
    }

    .description {
      padding: 0 $space-static-xl;
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      h3 {
        margin-top: 0;
      }

      .meta {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .meta-item {
          display: flex;
          flex-direction: column;
          flex-basis: 33%;
          
          .difficulty {
            &::before {
              content: '';
              width: 11px;
              height: 11px;
              border-radius: 50%;
              display: inline-block;     
              margin-right:  $space-static-xs;      
            }

            &.fundamental::before {
              background: $fundamental-bg;
            }

            &.intermediate::before {
              background: $intermediate-bg;
            }
            &.advanced::before {
              background: $advanced-bg;
            }
            &.expert::before {
              background: $expert-bg;
            }
          }
        }
      }
    }
  }
}