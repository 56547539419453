.revision-table {
  table {
    td {
      vertical-align: top;
    }
  }
  .revision-changes__list {
    button[class^='awsui_trigger'] {
      span {
        line-height: 1.9;
      }
    }

    .revision-changes__toggle-button {
      button {
        padding-left: 0 !important;
        &:hover {
          background: none !important;
          border-color: transparent !important;
        }
        span {
          font-weight: normal;
        }
      }
    }
  }
}
