.comment {
  display: flex;
  align-items: flex-start;
  .userAvatar {
      border-radius: 50%;
  }
  .userInfo {
      margin-left: 30px;
      p {
          margin: 0;
      }
      .userDetails {
          display: flex;
          margin: 10px 0;
          align-items: center;
          .timeAgo {
              margin-left: 20px;
              color: gray;
          }
      }
      .reactionIcons {
          display: flex;
          align-items: center;
          .icon {
              margin-right: 20px;
              cursor: pointer;
          }
          .report {
              margin-left: 10px;
              cursor: pointer;
              color: #3291e9;
              text-decoration: underline;
          }
      }
  }
}