.preparation-guide-container {
  .description {
    margin-top: 0 !important;
  }

  h4 {
    margin-top: 15px;
    margin-bottom: 5px;
  }

  .custom-link {
    text-decoration: underline !important;
  }

  div[class^='awsui_grid-column']:not(:last-child):after {
    content: '';
    position: absolute;
    width: 2px;
    height: 100%;
    background: #e9ebed;
    right: 2px;
    max-height: 46px;
    transform: translateY(15px);
  }
}
