.faq-container {
  div[class*='awsui_header'] {
    border-bottom: none !important;
  }
}
.ans-div-style {
  margin-left: 27px;
  ul,ol {
    margin-left: -20px;
  }
}