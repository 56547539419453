.not-complete-modal {
  display: flex;
  align-items: center;
  padding-left: 3rem;
}

.task-markdown-container {
  pre {
    white-space: pre-wrap;
  }
}
