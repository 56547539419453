.jam-challenge-details {
  margin-bottom: 100px;
  .markdown-div {
    max-width: 35rem;

    > * {
      max-width: 35rem;
    }

    code {
      display: inline-block;
      width: 35rem;
      overflow-x: scroll;
    }
  }
}
