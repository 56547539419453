.jam-sidebar {
  .spacer {
    height: 100px;
  }
  div[class*='awsui_section'] {
    div[class*='awsui_content'] {
      ul[class*='awsui_list'] {
        padding-left: 36px !important;
      }
    }
  }
}
