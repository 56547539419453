$primary-color: orange;
.star-rating {
    display: inline-block;
    font-size: 22px;
    svg {
        width: 22px;
        height: 22px;
        stroke: $primary-color;
    }
    &.filled {
        fill: $primary-color;
    }
    &.unfilled {
        fill: none;
    }
}