.awsui-visual-refresh {
  &.awsui-polaris-dark-mode {
    .leaderboard {
      .box {
        background-color: #000716;
      }
      table {
        thead {
          th {
            background-color: #000716 !important;
          }
        }
        tbody {
          tr > td {
            background-color: #000716 !important;
          }
        }
      }
    }
  }
  .leaderboard {
    span.countdown {
      color: #da8af6 !important;
      font-weight: bold;
      font-size: 24px;
    }
    .row {
      display: flex;
      flex-direction: row;
      &.selected {
        .box {
          border-color: #da8af6;
        }
      }
    }
    .my-team {
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    .box {
      background-color: white;
      border-radius: 15px;
      border: 2px solid #414d5c;
      display: flex;
      align-items: center;
      padding: 0 30px;
      &.pagination {
        border: none;
        padding: 2px 10px;
        margin-top: 10px;
      }
      &.timer {
        padding: 14px;
        h1 {
          line-height: 1 !important;
        }
      }
      &.flexed {
        flex: 1;
      }
      &.rank {
        width: 98px;
        height: 62px;
        justify-content: center;
        margin-right: 10px;
      }
      &.points {
        width: 170px;
        justify-content: center;
        height: 62px;
      }
      &.team-name {
        height: 62px;
        margin-right: 10px;
      }
    }
    .center {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    table {
      border-collapse: separate !important;
      border-spacing: 0 10px !important;
      thead {
        border-radius: 15px !important;
        th {
          height: 50px;
          background-color: white !important;
          border-bottom: none !important;
          &::before {
            border-left: none !important;
          }
          div[class*='awsui_header-cell-text'] {
            color: inherit !important;
          }
          &:first-child {
            border-radius: 4px 0 0 4px;
          }
          &:last-child {
            border-radius: 0 4px 4px 0;
          }
        }
      }
      tbody {
        tr {
          padding: 10px;
          border-radius: 15px !important;
          td {
            background-color: white !important;
            height: 71px;
            border-top: 2px solid #414d5c !important;
            border-bottom: 2px solid #414d5c !important;
            &:first-child {
              border-radius: 15px 0 0 15px;
              border-left: 2px solid #414d5c !important;
            }
            &:last-child {
              border-right: 2px solid #414d5c !important;
              border-radius: 0 15px 15px 0;
            }
          }
        }
      }
    }
    .report-button {
      z-index: 1;
      position: relative;
    }
    .winner-container {
      margin-top: 20px;
      .greetings {
        font-size: 36px;
        font-weight: bold;
        color: #da8af6;
        text-align: center;
      }
      .winners-trophy {
        height: 300px;
        margin-top: 10px;
        position: relative;
        .winners-trophy-inner {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          display: flex;
          justify-content: space-around;
          z-index: 2;
          .silver {
            margin-top: 30px;
            margin-bottom: -30px;
          }
          .gold {
            margin-top: 10px;
            margin-bottom: -10px;
          }
          .bronze {
            margin-top: 90px;
            margin-bottom: -90px;
          }
        }
      }
      .podium-img {
        width: 100%;
      }
      .winners-info {
        display: flex;
        justify-content: space-around;
        margin-top: 16px;
        > div {
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          .winner-position {
            margin-bottom: 6px;
            height: 38px;
            width: 98px;
            border-radius: 26px;
            text-align: center;
            color: #000000;
            font-size: 24px;
            font-weight: bold;
            display: flex;
            justify-content: center;
            align-items: center;
            align-self: center;
            &.second {
              background-color: #9797a9;
            }
            &.first {
              background-color: #e2a211;
            }
            &.third {
              background-color: #703015;
              color: white;
            }
          }
        }
      }
    }
  }
}
