.awsui-polaris-dark-mode {
  #dropzone {
    .dropzone{
      background-color: #101b2a !important; 
      border: 2px solid #687078 !important;
    }
    .dropzone .icon {
      color: #539FE5 !important;
    }
    .dropzone .drag-n-drop label {
      color: #539FE5 !important;
    }
  }
}

.awsui-polaris-light-mode{
  #dropzone {
    .dropzone .icon {
      color: #539FE5 !important;
    }
    .dropzone .drag-n-drop label {
      color: #539FE5 !important;
    }
  }
}

.teamSize {
  width: 105px;
}

.participants {
  width: 170px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  > * {
    width: 75px;
  }
}

.upload-image {
  display: flex;
  justify-content: space-between;
  border: 1px solid #0972d3;
  background-color: #f2f8fd;
  width: 500px;
  padding: 15px;
}

.image-container {
  margin-top: 30px;
}

.image-preview {
  display: flex;
  margin-top: 30px;
  .details-page,
  .thumbnail {
    margin-right: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    label {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 5px;
    }
  }
  .thumbnail {
    img {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }
  }
}

.rationale-container {
  position: relative;
  top: 10px;
  .alert-box {
    margin-bottom: 25px;
  }
  .challenge-container {
    margin-bottom: 20px;
    a {
      color:#539FE5 !important;
    }
    h4 {
      margin-bottom: 0;
      margin-top: 0;
    }
    .textarea-container {
      width: 70%;
    }
  }
}

#challenges-container {
  display: flex;
  flex-direction: column;
  width: 300px;
}

.challenge-item {
  margin-bottom: 20px;
  transition: all 0.2s ease;
  [data-challenge='challenge-item'] {
    cursor: move !important;
    h3 {
      span {
        display: flex;
        align-items: center;
        span {
          margin-right: 10px;
        }
      }
    }
  }
}

.event-template-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

