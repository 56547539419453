.filters {
  padding-bottom: 50px;
  .filters-title {
    padding-top: 13px;
    padding-bottom: 10px;
    padding-left: 20px;
    border-bottom: 1px solid #a9a6a657;
  }

  .filters-container {
    .filter__clearall-button {
      button {
        font-weight: normal !important;
        padding-left: 0 !important;
      }
    }
    display: flex;
    flex-direction: column;
    padding: 10px 30px 0;
    .filter__options {
      padding-left: 20px;
      padding-bottom: 10px;
      .filter__viewall-button {
        button {
          font-weight: normal !important;
          padding-left: 0 !important;
        }
      }
    }
    .divider {
      border-bottom: 1px solid #a9a6a657;
      margin-bottom: 10px;
    }
    .checkbox {
      padding: 3px !important;
    }
  }
}
