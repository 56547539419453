@import '~@amzn/awsui-design-tokens/polaris.scss';

$default-color: #D1D5DB;
$active-color: #539FE5;
$line-color: #7D8998;

.theme-toggle {

  div[class^="awsui_child"] {
   
    button {
      padding-right: 0 !important;
      padding-left: 0!important;
      color: $default-color !important;
    
      &[data-active="true"] {
        color: $active-color !important;
      }

      &:hover {
        border-color: transparent !important;
        background: inherit !important;
      }
    }

    &:first-child {
      > button {
        padding-right: $space-static-xs !important;

        &::after {
          content: "";
          position: absolute;
          width: 1px;
          background: $line-color;
          height: 20px;
          right: 0;
        }
      }
    }

    &:nth-child(2) {
      > button {
        margin-right: $space-static-xl !important;

        &::after {
          content: "";
          position: absolute;
          width: 1px;
          background: $line-color;
          height: 32px;
          right: -19px;
          top: -2px;
        }
      }
    }
  }
  
}


