
.awsui-visual-refresh {
  &.awsui-polaris-dark-mode {
    .report {
      .box {
        background-color: #000716;
      }
      table {
        thead {
          th {
            background-color: #000716 !important;
          }
        }
        tbody {
          tr {
            background-color: #000716 !important;
          }
        }
      }
    }
  }
}

div[data-classname='main-header'] {
  font-size: 42px;
}
.big-text{
  color: #DA8AF6;

/* Heading/Heading 1 */
font-family: Amazon Ember;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 30px; /* 125% */
letter-spacing: -0.64px;

}

.small-text {
  color: #DA8AF6;

/* Body/Paragraph Regular */
font-family: Amazon Ember;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px;
}

.social-button {
  width: 130px;
  height: 50px;
  border-radius: 8px;
  background: var(--grey-800, #0F1B2A);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
  .social-button-img {
    flex-basis: 20%;
  }
}

.title {

/* Special/Large Light */
font-family: Amazon Ember;
font-size: 42px;
font-style: normal;
font-weight: 300;
letter-spacing: -1.44px;
}
.custom-hr {
  background: #414D5C !important;
}