.key-value-pair-row {
  padding: 16px 0px;
  display: flex;
  justify-content: space-between;
  [data-classname='value-link'] {
    color: #539fe5 !important;
  }
  [data-classname='value-primary'] {
    color: #da8af6 !important;
  }
  [data-classname='value-secondary'] {
    color: #414d5c !important;
  }
  &.bordered {
    border-bottom: 1px solid #414d5c;
  }
  &.last {
    padding-bottom: 0;
  }
}
