.markdonw-preview-editor {
  /* CSS */
  .main-container {
    border: 1px solid var(--grey-200, #e9ebed);
    border-radius: 15px;
  }
  .button-container {
    padding: 20px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
  .custom-btn {
    align-items: center;
    background-color: #ffffff;
    border: 1px solid var(--blue-600, #0972d3);
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
    box-sizing: border-box;
    color: var(--blue-600, #0972d3);
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    justify-content: center;
    line-height: 20px;
    margin: 0;
    min-height: 3rem;
    padding: calc(0.875rem - 1px) calc(1.5rem - 1px);
    position: relative;
    text-decoration: none;
    transition: all 250ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    width: auto;
  }

  .custom-btn:hover,
  .custom-btn:focus {
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    color: var(--blue-600, #0972d3);
  }

  .custom-btn:hover {
    transform: translateY(-1px);
  }

  .custom-btn:active {
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
    color: var(--blue-600, #0972d3);
    transform: translateY(0);
  }
}
