.awsui-visual-refresh {
  &.awsui-polaris-dark-mode {
    div.join-team-container {
      background-color: #000716;
      li[class*='awsui_card'] {
        > div[class*='awsui_card-inner'] {
          background-color: #000716 !important;

          div[data-classname='card-pin'] {
            background-color: #414d5c;
          }
        }
      }
    }
  }
  .join-team-container {
    border: 2px solid #414d5c;
    background-color: #fff;
    border-radius: 15px;
    padding: 20px 24px;
    li[class*='awsui_card'] {
      > div[class*='awsui_card-inner'] {
        &::before {
          border: 2px solid #414d5c !important;
        }
        &::after {
          box-shadow: none !important;
        }
        div[data-classname='card-pin'] {
          background-color: #ccc;
          display: inline-block;
          padding: 3px 8px;
          border-radius: 4px;
        }
      }
    }
    li[class*='awsui_card-selected'] {
      > div[class*='awsui_card-inner'] {
        &::before {
          border-color: #539fe5 !important;
        }
      }
    }

    div.divider {
      height: 2px;
      width: 100%;
      background-color: #414d5c;
    }
  }
}
