.awsui-visual-refresh {
  &.awsui-polaris-dark-mode {
    .collaborators-header {
      background-color: #0f1b2a !important;
    }
  }
}

.collaborators-header {
  height: 128px;
  background-color: var(--grey-200, #E9EBED);
  border-radius: 8px;
  padding: 20px;
}
