$primary-color: rgb(2, 117, 216);
.difficulty {
  display: inline-block;
  font-size: 22px;
  svg {
    width: 20px;
    height: 20px;
    stroke: $primary-color;
  }
  &.filled {
    fill: $primary-color;
  }
  &.unfilled {
    fill: none;
  }
}
